import Markdown from 'react-markdown'
import React, { useState } from 'react'
import { Check, CopyAll, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Message } from '../types'
import { SourcesView } from './SourcesView'
import { marked } from 'marked'
import { Button } from './Buttons'

type ExpandablePanelProps = {
  title: React.ReactNode
  children: React.ReactNode
  variant?: 'normal' | 'quotation' | 'blue'
}

const snakeToSentence = (snakeCasedString: string) =>
  snakeCasedString.replace(/_/g, ' ')

export const ExpandablePanel = ({
  title,
  children,
  variant,
}: ExpandablePanelProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const headerStyles = {
    normal: 'p-1 bg-transparent text-gray-500',
    blue: 'p-2 text-blue-700 bg-blue-50 shadow border',
    quotation: 'p-2 bg-white bg-gray-100 shadow border bg-white',
  }

  return (
    <div
      className={
        'flex flex-col rounded ' +
        (isExpanded ? 'bg-white border' : 'opacity-80 hover:opacity-100')
      }
    >
      <div
        className={`cursor-pointer flex justify-between items-center gap-1 ${headerStyles[variant || 'normal']}`}
        onClick={toggleExpand}
      >
        <div className="flex-1">{title}</div>
        <div className="text-xs text-gray-500">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      {isExpanded && <div className={`overflow-hidden p-3`}>{children}</div>}
    </div>
  )
}

type Props = {
  conversation: Message[]
  emptyConversationPlaceholder: React.ReactNode
}
export const Chat = ({ conversation, emptyConversationPlaceholder }: Props) => {
  const waitingForBotToRespond =
    conversation.length > 0 &&
    conversation[conversation.length - 1].role === 'user'
  const conversationEmpty = conversation.length === 0

  return (
    <div
      className={
        'overflow-y-auto flex-1 bg-gray-100 rounded-t-3xl flex flex-col items-center ' +
        (conversationEmpty ? 'justify-center' : '')
      }
    >
      <div className="p-4 flex flex-col gap-4 lg:max-w-5xl w-full">
        {conversationEmpty && emptyConversationPlaceholder}
        {conversation.map((message, index) => {
          const text = message.text
          const CustomAnchor = (props: any) => {
            return props.href.match(/^https?:\/\//) ? (
              <a
                {...props}
                href={props.href}
                target="_blank"
                rel="noreferrer"
                className={'text-blue-800'}
              >
                {props.children}
              </a>
            ) : (
              <a
                {...props}
                className={
                  'text-fuchsia-800 p-1 no-underline align-top text-xs'
                }
              >
                {props.children}
              </a>
            )
          }
          return (
            <div key={index} className={'flex flex-col gap-2'}>
              <ChatBubble
                key={index}
                color={message.role === 'assistant' ? 'transparent' : 'fancy'}
              >
                {message.steps && message.steps.length > 0 && (
                  <div key={index} className={'flex flex-col gap-2'}>
                    {message.steps.map((step, index) => {
                      const isInProgress =
                        index === message.steps!.length - 1 &&
                        message.text == ''
                      return (
                        <ExpandablePanel
                          variant={'normal'}
                          title={
                            <div className={'flex gap-2 justify-between'}>
                              <div className={'flex items-center gap-2'}>
                                {isInProgress ? (
                                  <BotThinkingSpinner />
                                ) : (
                                  <BotDoneThinking />
                                )}
                                {step.sentence ? (
                                  <span>{step.sentence}</span>
                                ) : (
                                  <div className={'flex items-center gap-2'}>
                                    {snakeToSentence(step.name)}
                                    <Dict dict={step.args} />
                                  </div>
                                )}
                              </div>
                              <div
                                className={'flex items-center gap-2 text-sm'}
                              >
                                {step.documents
                                  ? step.documents.length + ' treff'
                                  : ''}
                              </div>
                            </div>
                          }
                        >
                          <SourcesView
                            documents={step.documents || []}
                            highlightedDocumentIds={[]}
                          />
                        </ExpandablePanel>
                      )
                    })}
                  </div>
                )}
                {message.facts && (
                  <div className={'flex flex-col gap-1 mt-2'}>
                    {message.facts.map((fact, index) => {
                      const sourceUrl = fact.source_document_url

                      const documentsContent = (message.documents || []).filter(
                        (doc) => (doc.source as string).indexOf(sourceUrl) > -1,
                      )

                      return (
                        <ExpandablePanel
                          variant={'normal'}
                          key={index}
                          title={
                            <div className={'flex flex-col gap-1 text-sm'}>
                              {fact.exact_quotes.map((e: any) => (
                                <div
                                  className={'border-l-4 p-1 border-gray-200'}
                                >
                                  {e}
                                </div>
                              ))}
                              <span className={'text-right italic'}>
                                fra{' '}
                                <a className={'text-blue-700'} href={sourceUrl}>
                                  {sourceUrl}
                                </a>
                              </span>
                            </div>
                          }
                        >
                          <div className={'text-sm'}>
                            Fra <a href={sourceUrl}>{sourceUrl}</a>:
                          </div>
                          {documentsContent.map((doc, index) => (
                            <div
                              className={
                                'border-l-8 bg-gray-50 p-2 text-sm prose lg:prose prose-stone'
                              }
                            >
                              <Markdown key={index}>{doc.content}</Markdown>
                            </div>
                          ))}
                        </ExpandablePanel>
                      )
                    })}
                  </div>
                )}
                <div className={'prose lg:prose prose-stone'}>
                  <Markdown
                    components={{
                      a: CustomAnchor,
                    }}
                  >
                    {text}
                  </Markdown>
                </div>
                {message.role === 'assistant' && (
                  <div className={'mt-4 p-2 flex gap-2'}>
                    <CopyResponseButton text={text} />
                  </div>
                )}
              </ChatBubble>
              {/*{message.documents && (*/}
              {/*  <SourcesView*/}
              {/*    documents={message.documents}*/}
              {/*    highlightedDocumentIds={idsOfMentionedSources || []}*/}
              {/*  />*/}
              {/*)}*/}

              {message.citations && (
                <div className={'flex flex-col gap-2'}>
                  {message.citations.map((citation, index) => (
                    <blockquote
                      key={index}
                      className={
                        'p-2 bg-gray-100 border-l-8 flex flex-col gap-2'
                      }
                    >
                      <div className={'font-bold'}>
                        {citation.factual_statement_from_text}
                      </div>
                      <div>{citation.source_document_id}</div>
                      <div>{citation.exact_quote}</div>
                    </blockquote>
                  ))}
                </div>
              )}
            </div>
          )
        })}

        {waitingForBotToRespond && (
          <ChatBubble color={'transparent'}>
            <div className={'flex gap-3 items-center'}>
              <BotThinkingSpinner />
              <div>Assistenten tenker...</div>
            </div>
          </ChatBubble>
        )}
      </div>
    </div>
  )
}

const Dict = ({ dict }: { dict: object }) => {
  return (
    <div
      className={
        'flex flex-col gap-1 bg-gray-200 p-1 border border-gray-300 shadow rounded-lg'
      }
    >
      {Object.entries(dict).map(([key, value]) => (
        <div key={key} className={'flex gap-2 items-center'}>
          <div className={'font-bold text-xs uppercase'}>{key}</div>
          <div>
            {Array.isArray(value) ? (
              <ul>
                {value.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <div>{value}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const BotThinkingSpinner = () => (
  <div
    className={
      'animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-700'
    }
  ></div>
)

const BotDoneThinking = () => (
  <div className={''}>
    <Check fontSize={'small'} />
  </div>
)

// Configure the marked renderer to avoid <p> tags
const customMarkdownRenderer = new marked.Renderer()
customMarkdownRenderer.paragraph = (paragraph) =>
  `${customMarkdownRenderer.parser.parseInline(paragraph.tokens)}\n\n`
const markdownToHtml = (markdown: string) =>
  (marked(markdown, { renderer: customMarkdownRenderer }) as string).trim()

export const CopyResponseButton = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyResponse = () => {
    navigator.clipboard.writeText(markdownToHtml(text) as string).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
    })
  }

  return (
    <Button onClick={copyResponse}>
      {isCopied ? <Check /> : <CopyAll />}
      {isCopied ? 'Kopiert!' : 'Kopier svar'}
    </Button>
  )
}

type ChatBubbleProps = {
  children: React.ReactNode
  color: 'transparent' | 'fancy'
}
export const ChatBubble = (p: ChatBubbleProps) => {
  const bubbleClasses = {
    // User message must pad to the right, and have a chat bubble arrow on the right
    transparent: 'rounded-r-lg pr-4 mr-auto',
    fancy: `bg-fancy-pink rounded-r-lg pr-4 ml-auto border-2`,
  }
  return (
    <div
      className={`flex flex-col gap-4 font-poppins p-3 rounded-lg ${bubbleClasses[p.color]}`}
    >
      {p.children}
    </div>
  )
}
