import React, { useEffect, useState } from 'react'
import './App.css'
import { useMsal } from '@azure/msal-react'
import { apiSearchScopeAction } from './authConfig'

import UserIcon from '@mui/icons-material/AccountCircle'
import useCustomerConfig from './hooks/useCustomerConfig'
import AddCommentIcon from '@mui/icons-material/AddComment'
import { Chat } from './components/Chat'
import { FeedbackExplorer } from './components/FeedbackExplorer'
import { Message } from './types'
import { ChatInputBox } from './components/ChatInputBox'
import { GiveFeedback } from './components/GiveFeedback'
import { Button } from './components/Buttons'
import MenuIcon from '@mui/icons-material/Menu'
import Tune from '@mui/icons-material/Tune'
import MapsUgcIcon from '@mui/icons-material/MapsUgc'
import {
  createNewChatbotThread,
  getMe,
  postChatMsgToThreadAndStreamResponse,
} from './chatApi'

const UserIconAndName = () => {
  const { instance, accounts } = useMsal()
  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div
      className="flex items-center gap-2 flex-col p-4"
      tabIndex={0}
      role={'button'}
      onClick={handleLogin}
    >
      <UserIcon style={{ fontSize: 40 }} />
      {accounts && accounts.length ? (
        <span>{accounts[0].name}</span>
      ) : (
        <span>Logg in</span>
      )}
    </div>
  )
}

const Sidebar = ({
  extraChildren,
  isOpen,
}: {
  extraChildren?: React.ReactNode
  isOpen: boolean
}) => {
  const customerConfig = useCustomerConfig()

  return (
    <header
      className={`bg-gray-50 h-full flex flex-col items-center justify-between ${isOpen ? 'w-60 p-4' : 'flex-0 w-0'} transition-all duration-500 ease-in-out`}
    >
      <div className="flex flex-col items-center">
        <h1 className="text-2xl font-bold m-0">{customerConfig.header}</h1>
        <span>{customerConfig.subheader}</span>
      </div>
      <div>
        <UserIconAndName />
        {extraChildren}
      </div>
    </header>
  )
}

const getAccessTokenForSemanticApi = async (accounts: any, instance: any) => {
  const accessTokenRequest = {
    scopes: [apiSearchScopeAction],
    account: accounts[0],
  }
  try {
    const accessTokenResponse =
      await instance.acquireTokenSilent(accessTokenRequest)
    return accessTokenResponse.accessToken
  } catch (error) {
    console.log('Silent token acquisition fails. Acquiring token using popup')
    try {
      const accessTokenResponse =
        await instance.acquireTokenRedirect(accessTokenRequest)
      return accessTokenResponse.accessToken
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

const App = () => {
  const { instance, accounts } = useMsal()
  const customerConfig = useCustomerConfig()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [threadId, setThreadId] = useState<string | null>(null)
  const [conversation, setConversation] = useState<Message[]>([])
  const [botIsTyping, setBotIsTyping] = useState(false)
  const [customSystemPrompt, setCustomSystemPrompt] = useState<string | null>(
    null,
  )
  const [me, setMe] = useState<any>(null)

  const startNewConversationThread = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newThreadId = await createNewChatbotThread(
      token,
      customerConfig.chatServer,
    )
    setThreadId(newThreadId)
    setConversation([])
  }

  const fetchMe = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    }
    const me = await getMe(token, customerConfig.chatServer)
    if (me) {
      setMe(me)
      if (me.systemPrompt && customSystemPrompt === null) {
        setCustomSystemPrompt(me.systemPrompt)
      }
    }
  }

  useEffect(() => {
    if (accounts.length > 0 && instance && threadId === null) {
      const timer = setTimeout(startNewConversationThread, 250)
      fetchMe()
      return () => clearTimeout(timer)
    }
  }, [accounts, instance, threadId])

  const onFeedbackSubmit = async (grade: number, comment: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else {
      const res = await fetch(customerConfig.chatServer + '/feedback', {
        method: 'POST',
        body: JSON.stringify({ grade, comment, conversation }),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      })
    }
  }

  const onSubmitChatMessage = async (messageText: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newConversation = [
      ...conversation,
      { role: 'user', text: messageText } as Message,
    ]
    setConversation(newConversation)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else if (!threadId) {
      console.error('No thread id')
      return
    } else {
      const responseStream = postChatMsgToThreadAndStreamResponse(
        token,
        customerConfig.chatServer,
        threadId,
        messageText,
        customSystemPrompt,
      )
      setBotIsTyping(true)
      const updateAssistantAnswer = (updateFn: (msg: Message) => Message) => {
        setConversation((conversation) => {
          const last = conversation[conversation.length - 1]
          if (last.role === 'assistant') {
            return [...conversation.slice(0, -1), updateFn(last)]
          } else {
            const newMessage: Message = {
              role: 'assistant',
              text: '',
              steps: [],
              documents: [],
              citations: [],
            }
            return [...conversation, updateFn(newMessage)]
          }
        })
      }
      for await (const chunk of responseStream) {
        if (chunk.answer) {
          updateAssistantAnswer((last) => ({
            ...last,
            text: last.text + chunk.answer,
          }))
        }
        if (chunk.documents) {
          updateAssistantAnswer((last) => ({
            ...last,
            // Find the step with the same runId, and append documents to it:
            steps: (last.steps || []).map((step) => {
              if (step.runId === chunk.runId) {
                return { ...step, documents: chunk.documents }
              } else {
                return step
              }
            }),
            documents: [...(last.documents || []), ...chunk.documents],
          }))
        }
        if (chunk.citations) {
          updateAssistantAnswer((last) => ({
            ...last,
            citations: [...(last.citations || []), ...chunk.citations],
          }))
        }
        if (chunk.facts) {
          updateAssistantAnswer((last) => ({
            ...last,
            facts: [...(last.facts || []), ...chunk.facts],
          }))
        }
        if (chunk.removedDocumentIds) {
          updateAssistantAnswer((last) => ({
            ...last,
            documents: (last.documents || []).filter(
              (doc) => !chunk.removedDocumentIds.includes(doc.id),
            ),
          }))
        }
        if (chunk.steps) {
          updateAssistantAnswer((last) => ({
            ...last,
            steps: [...(last.steps || []), ...chunk.steps],
          }))
        }
      }
      setBotIsTyping(false)
    }
  }
  const conversationEmpty = conversation.length === 0
  const waitingForBotToRespond =
    conversation.length > 0 &&
    conversation[conversation.length - 1].role === 'user'

  return (
    <div className="h-screen flex">
      <Sidebar
        isOpen={sidebarOpen}
        extraChildren={
          <div className={'flex flex-col justify-center gap-2 p-1'}>
            {!conversationEmpty && (
              <>
                <Button
                  onClick={startNewConversationThread}
                  variant={'primary'}
                >
                  <AddCommentIcon />
                  Ny samtale
                </Button>
                <GiveFeedback onSubmit={onFeedbackSubmit} />
              </>
            )}
            {accounts && accounts.length > 0 && (
              <>
                <FeedbackExplorer
                  getToken={() =>
                    getAccessTokenForSemanticApi(accounts, instance)
                  }
                />
                {me && me.canUseCustomSystemPrompt && (
                  <CustomSystemPromptDialog
                    customSystemPrompt={customSystemPrompt}
                    setCustomSystemPrompt={setCustomSystemPrompt}
                  />
                )}
              </>
            )}
          </div>
        }
      />
      <div
        className={
          'flex flex-col bg-gray-50 ' + (sidebarOpen ? 'flex-1' : 'flex-1')
        }
      >
        <nav className={'flex justify-between items-center p-3 text-blue-600'}>
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className={'cursor-pointer'}
          >
            <MenuIcon />
          </button>
          {/*<img src={customerConfig.logo} className="h-14 mb-4" alt="logo" />*/}
          <h1 className="text-2xl font-bold m-0">
            Semantic <span className={'bg-blue-600 text-white p-2'}>Lab</span>
          </h1>
          <button
            onClick={startNewConversationThread}
            className={'cursor-pointer'}
          >
            <MapsUgcIcon />
          </button>
        </nav>
        <Chat
          conversation={conversation}
          emptyConversationPlaceholder={
            <div
              className={
                'flex-1 flex flex-col items-center text-xl text-gray-500'
              }
            >
              <p className={''}>
                <AddCommentIcon fontSize={'large'} /> Velkommen til assistenten!
                Still spørsmål i boksen nederst for å starte en samtale.
              </p>
            </div>
          }
        />

        {/*<button className={'text-white bg-blue-600 fixed bottom-2 right-2'} onClick={startNewConversationThread}>Se på kilder</button>*/}

        <div className="p-3 bg-gray-100">
          <ChatInputBox
            onSubmit={onSubmitChatMessage}
            quickTextActions={
              conversationEmpty ? [] : customerConfig.quickTextActions
            }
            canSubmit={
              !waitingForBotToRespond && !botIsTyping && threadId !== null
            }
          />
        </div>
      </div>
    </div>
  )
}

type CustomSystemPromptDialogProps = {
  customSystemPrompt: string | null
  setCustomSystemPrompt: (customSystemPrompt: string | null) => void
}

const CustomSystemPromptDialog = (props: CustomSystemPromptDialogProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [tempPrompt, setTempPrompt] = useState<string | null>(
    props.customSystemPrompt,
  )

  const openDialog = () => {
    setTempPrompt(props.customSystemPrompt)
    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const savePrompt = () => {
    props.setCustomSystemPrompt(tempPrompt)
    setIsOpen(false)
  }

  return (
    <>
      <Button onClick={openDialog}>
        <Tune />
        Sett systemprompt
      </Button>
      {isOpen && (
        <div
          className={
            'fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center text-black z-50'
          }
        >
          <div className={'bg-white w-2/4 p-4 rounded shadow'}>
            <h2 className={'text-xl'}>Sett systemprompt</h2>
            <textarea
              rows={21}
              className={'w-full p-2 border-2 rounded bg-gray-50'}
              placeholder={'Skriv inn systemprompt her...'}
              value={tempPrompt || ''}
              onChange={(e) => setTempPrompt(e.target.value)}
            />
            <div className={'flex gap-2 justify-end'}>
              <Button onClick={closeDialog}>Avbryt</Button>
              <Button onClick={savePrompt} variant={'primary'}>Lagre</Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default App
